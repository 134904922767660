import "$styles/index.scss"
import "$styles/syntax-highlighting.css"

import "bootstrap/dist/js/bootstrap.bundle"
import "@popperjs/core"

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}"

console.info("Bridgetown is loaded!")
